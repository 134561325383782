<!--
 * @Author: zhangyutong
 * @Date: 2021-08-16 19:25:47
 * @LastEditTime: 2022-04-13 16:40:18
 * @LastEditors: Please set LastEditors
 * @FilePath: \lightning-web\src\views\commonObjects\marketingInvitation\maketingInvitationShow.vue
-->
<template>
  <div class="objectTableMain">
    <div class="object_box">
      <div class="pull-left topContent">
        <div class="right-content">
          <el-button type="primary" size="mini" @click="newPost">{{
            $t("label.import.page1.newcreate")
          }}</el-button>
        </div>
        <el-breadcrumb
          separator-class="el-icon-arrow-right"
          style="margin: 10px 10px 6px 10px"
        >
          <el-breadcrumb-item @click.native="goUrl(-1)">{{
            $store.state.channel.tabName
          }}</el-breadcrumb-item>
          <el-breadcrumb-item @click.native="goUrl(-1)">{{
            $store.state.channel.bigTitle
          }}</el-breadcrumb-item>
          <!-- <el-breadcrumb-item>员工邀约</el-breadcrumb-item> -->
        </el-breadcrumb>
        <!-- 员工邀约 -->
        <div class="header-title">{{$t('c62')}}</div>
      </div>
      <div class="pull-left topContent pl-10 mb-10">
        <statistics
          :total="totalSize"
          :isQueryCount="true"
          :time-interval="timeInterval"
          :show-checked-length="false"
          @refresh="refresh"
        ></statistics>
      </div>
      <table-panel
        class="text-center"
        ref="tablePanel"
        :border="true"
        :show-lock="false"
        :table-height="tableHeight"
        :checked="true"
        :pict-loading="pictLoading"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :use-hyperlinks="false"
        :tableButton="operationButtonList"
        @delete="del"
        @edit="edit"
        @share="share"
        @onchange="onchange"
      >
      </table-panel>
    </div>
    <!-- 分页 -->
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageCount"
        :page-sizes="pageSizes"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="totalSize"
      >
      </el-pagination>
      <span style="margin-left: 10px">
        {{ $t("label.sales.dashboard.number") }} {{ pageCount }}
        {{ $t("label.tabpage.page") }}
      </span>
    </div>
    <!-- soical详情 -->
    <detailSoical ref="detailSoical"></detailSoical>
    <!-- 删除数据提示 -->
    <el-dialog
      :visible.sync="dialogVisible"
      :title="$t('label.emailtocloudcc.delete')"
      top="15%"
      width="25%"
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <!-- 确认删除该条数据吗 -->
      <span style="font-size: 14px; color: #080707">{{
        delFlg === 1
          ? $t("label.weixin.confirm.delete")
          : $t("vue_label_commonobjects_view_notice_deletecertification", {
              selectNum: this.selectedList.length,
            })
      }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="mini">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button @click="confirmDel" class="el-button--primary" size="mini">
          <!-- 删除 -->
          {{ $t("component_setup_tabs_label_delete") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 分享 -->
    <shareFrom ref="shareFrom"></shareFrom>

    <dialogInvitation ref="dialogInvitation"></dialogInvitation>
  </div>
</template>

<script>
import { TablePanel, Statistics } from "@/components/index";
import detailSoical from "@/views/social/components/detailSoical.vue";
import * as SetViewApi from "@/views/marketList/api.js";
import * as CommonObjApi from "../api";
import shareFrom from "@/views/marketList/marketingInvitation/component/shareFrom.vue";
import dialogInvitation from "@/views/marketList/marketingInvitation/component/dialogInvitation.vue";

export default {
  components: {
    TablePanel,
    Statistics,
    detailSoical,
    shareFrom,
    dialogInvitation,
  },
  data() {
    return {
      //分页
      delFlg: 1,
      dialogVisible: false,
      selectNum: 0,
      selectedList: [],
      ids: "",
      pictLoading: false,
      dataList: [],
      pageSize: 50, // 每页展示数据条数
      pageSizes: [10, 25, 50, 100, 200], //每页显示个数
      totalSize: "",
      lastRefresh: new Date(), // 最后一次更新时间
      timer: "",
      timeInterval: "",
      tableHeight: `${document.body.offsetHeight - 260}px`,
      tableAttr: [],
      // this.$i18n.t('label.emailtocloudcc.button.view')
      operationButtonList: {
        buttonList: [
          {
            label: "删除",
            notShow: true,
            action: "delete",
            cssClass: "small",
          },
          {
            label: "编辑",
            notShow: true,
            action: "edit",
            cssClass: "small",
          },
          {
            label: "分享",
            notShow: true,
            action: "share",
            cssClass: "small",
          },
        ],
        align: "center",
        width: "80",
      },
      pageObj: {
        dataList: [],
      },
      objId: this.$route.params.id,
      searchKeyWord: "",
      fields: "",
      relatedlistId: "",
      recordId: "",
      layoutId: "",
      list: [],
      id: "",
    };
  },
  computed: {
    pageCount() {
      let { totalSize, pageSize } = this;
      return Math.ceil(totalSize / pageSize);
    },
  },
  created() {
    var _this = this;
    document.onkeydown = function () {
      //按下回车提交
      let key = window.event.keyCode;
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.getRelationList();
      }
    };
    this.getRelationList();
  },
  mounted() {
    // 计时器计算多久前更新
    this.timer = setInterval(() => {
      // 获取当前时间
      let currentTime = new Date();
      let interval = currentTime - this.lastRefresh;
      //计算出相差天数
      var days = Math.floor(interval / (24 * 3600 * 1000));
      //计算出小时数
      var leave1 = interval % (24 * 3600 * 1000); //计算天数后剩余的毫秒数
      var hours = Math.floor(leave1 / (3600 * 1000));
      //计算相差分钟数
      var leave2 = leave1 % (3600 * 1000); //计算小时数后剩余的毫秒数
      var minutes = Math.floor(leave2 / (60 * 1000));
      //计算相差秒数
      var leave3 = leave2 % (60 * 1000); //计算分钟数后剩余的毫秒数
      var seconds = Math.round(leave3 / 1000);
      hours = minutes > 30 ? hours + 1 : hours;
      if (days > 0) {
        this.timeInterval =
          hours > 0
            ? `${days} ${this.$i18n.t(
                "label.login.access.day"
              )}${hours} ${this.$i18n.t("label_tabpage_hoursagoz")}`
            : `${days}${this.$i18n.t("label_tabpage_Sdfez")}`;
      } else if (hours > 0) {
        this.timeInterval = `${hours} ${this.$i18n.t(
          "label_tabpage_hoursagoz"
        )}`;
      } else if (minutes > 0) {
        this.timeInterval = `${minutes} ${this.$i18n.t(
          "label_tabpage_minutesagoz"
        )}`;
      } else {
        this.timeInterval =
          seconds > 43
            ? this.$i18n.t("vue_label_commonobjects_view_secondsbefore", {
                seconds: seconds,
              })
            : this.$i18n.t("label.notification.time1");
      }
    }, 1000);
  },
  methods: {
    newPost() {
      this.$refs.dialogInvitation.init();
    },
    confirmDel() {
      CommonObjApi.deleteEmployeeInvited({ id: this.id }).then((res) => {
        if (res.result) {
          this.$message.success("删除成功");
          this.dialogVisible = false;
          this.getRelationList();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    onchange(val) {
      this.selectedList = val;
      let ids = "";
      for (var i = 0; i < val.length; i++) {
        ids += val[i].id + ",";
      }
      //去掉最后一个逗号(如果不需要去掉，就不用写)
      if (ids.length > 0) {
        ids = ids.substr(0, ids.length - 1);
      }
      this.ids = ids;
    },
    share(val) {
      this.$refs.shareFrom.init(val.data.id);
    },
    edit(val) {
      
      this.$refs.dialogInvitation.init(val.data.id);
    },
    del(val) {
      this.dialogVisible = true;
      this.id = val.data.id;
    },
    getRelationList() {
      this.pictLoading = true;
      SetViewApi.GetRelationList({ recordId: this.$route.query.id }).then(
        (res) => {
          if (res.result) {
            this.list = res.data;
            this.getHeadArr();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: res.returnInfo,
            });
          }
        }
      );
    },
    // 获取相关列表数据
    getHeadArr() {
      this.list.forEach((element) => {
        if (element.relatedlistType === "invited") {
          this.relatedlistId = element.relatedlistId;
          this.layoutId = element.layoutId;
        }
      });
      let data = {
        recordId: this.$route.query.id,
        layoutId: this.layoutId,
        relatedlistId: this.relatedlistId,
        attachementType: "",
      };
      SetViewApi.GetDetailRelatedItems(data).then((res) => {
        if (res.result && res.data.fieldList) {
          this.tableAttr = [];
          res.data.fieldList.forEach((list) => {
            let obj = {
              apiname: list.name,
              schemefieldLength: list.length,
              nameLabel: list.label,
              schemefieldType: "S",
              schemefieldName: list.name,
              type: list.type,
            };
            this.tableAttr.push(obj);
          });
          this.tableAttr = this.getName(this.tableAttr);
          this.pictLoading = false;
          this.$refs.tablePanel.objModifyAll = true;
          this.dataList = res.data.data;
          this.dataList.forEach((element) => {
            element.attachtype = true;
          });
          this.totalSize = res.data.data.length;
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
          
          this.showpage();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: res.returnInfo,
          });
        }
      });
    },
    getName(item) {
      item.forEach((val) => {
        if (
          val.type == "S" &&
          (val.apiname == "formid" ||
            val.apiname == "createbyid" ||
            val.apiname == "lastmodifybyid" ||
            val.apiname == "ownerid" ||
            val.apiname == "inviteduser" ||
            val.apiname == "campaignid")
        ) {
          val.apiname += "ccname";
          val.schemefieldName += "ccname";
        }
      });
      return item;
    },
    showpage() {
      if (this.dataList === null && this.dataList === []) {
        this.totalSize = 0;
      } else if (this.pageCount === 1) {
        this.pageObj.dataList = this.dataList.slice(0, this.pageSize);
      } else {
        let num = this.pageCount * this.pageSize;
        let numNext = (this.pageCount - 1) * this.pageSize;
        this.pageObj.dataList = this.dataList.slice(numNext, num);
      }
    },
    // 显示页数发生变化时
    handleSizeChange(val) {
      this.pageSize = val;
      this.showpage();
    },
    // 当前页码发生变化时
    handleCurrentChange(val) {
      this.pageCount = val;
      this.$refs.tablePanel.$refs.tablePanel.bodyWrapper.scrollTop = 0;
      this.showpage();
    },
    goUrl(index) {
      this.$router.go(index);
    },
    // 刷新
    refresh() {
      this.getRelationList();
      this.lastRefresh = new Date();
      // 重置列表信息
      // 重置列表选中统计数据
      this.$refs.tablePanel.checkedList = [];
    },
    // 搜索
    search() {
      this.page = 1;
      this.pageObj.dataList = [];
      this.getRelationList();
    },
  },
  beforeDestroy() {
    // 在Vue实例销毁前，清除我们的定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
  },
};
</script>
<style lang='scss' scoped>
.block {
  background: #fff;
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  z-index: 9;
}
.header-title {
  font-family: PingFangSC-Medium;
  font-size: 16px;
  color: #080707;
  letter-spacing: 0;
  line-height: 30px;
  margin-left: 10px;
}
.topContent {
  width: 100%;
}
.objectTableMain {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: column;
  .object_box {
    border-radius: 3px;
    border: 1px solid #dedcda;
    // height: calc(100% - 50px);
    flex: 1;
    overflow: inherit;
    background: #f5f5f5;
    display: flex;
    flex-direction: column;
  }
}
.right-content {
  position: absolute;
  right: 10px;
  margin: 10px 10px 6px;
}
::v-deep .el-breadcrumb__inner {
   color: #006dcc;
   cursor: pointer;
}

::v-deep .el-breadcrumb__item:last-child .el-breadcrumb__inner{
 color: #006dcc;
 cursor: pointer;
}
</style>